import React from 'react';
import {useNavigate} from 'react-router-dom';
import languages from "../../core/config/lang.config";

//state
import {useRecoilState} from "recoil";
import langAtom from "../../core/atoms/langAtom";

interface LangProps {
    langList: any[],
    default: string
}

const Lang = (props: LangProps) => {

    const [lang, setLang] = useRecoilState(langAtom);
    const navigate = useNavigate();

    const changeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLang(e.target.value)
        localStorage.setItem('lang', e.target.value)
        if(document && document.querySelector("#bubbles")) {
            // @ts-ignore
            //document.querySelector("#filter-notes").remove();
            document.querySelector("#bubbles").remove();
            // @ts-ignore
            document.querySelector("#tooltip-viz").remove();
            // @ts-ignore
            document.querySelector("#vt-recommendation").classList.remove("active");
        }
        return navigate('/');
    }

    return props.langList.length > 1
        ? <select onChange={changeHandler}
                  style={{position:"absolute", top: "10px", right: "10px"}}
                  value={props.default}>
            {
                props.langList.map((l:"en"|"ru"|"fr"|"vi") => {
                    return <option value={l}
                                   key={'lang'+l}>
                        {languages[l]}
                    </option>
                })
            }
        </select> : null

}

export default Lang;