import React from 'react';
import {useLocation} from "react-router-dom";

//state
import {useRecoilState} from "recoil";
import docsAtom from "../core/atoms/docsAtom";
import countryAtom from "../core/atoms/countryAtom";
import wordsAtom from "../core/atoms/wordsAtom";

//components
import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";

//utils
import get from "../core/utils/get";
import capitalize from "../core/utils/capitalize";

const Docs = () => {

    const {pathname} = useLocation();

    const mark = get('mark');

    const [docs] = useRecoilState(docsAtom);
    const [country] = useRecoilState(countryAtom);
    const [words] = useRecoilState(wordsAtom);

    const [cur, setCur] = React.useState<any>(null);
    const [parts, setParts] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (mark && parts && document.getElementById("id" + mark)) {
            // @ts-ignore
            document.getElementById("id" + mark).scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [parts]);

    React.useEffect(() => {
        const url = pathname.replace('/doc/', '');
        // @ts-ignore
        if (docs && docs.docs && docs.docs.list) {
            // @ts-ignore
            const d = ((Object.entries(docs.docs.list).find((doc: any) => {
                return (doc[1].doc_alias === url)
            })))
            setCur(d ? d[1] : {});
        }
    }, [docs])

    React.useEffect(() => {
        // @ts-ignore
        if (docs && docs.recommendations && docs.recommendations.list) {
            // @ts-ignore
            const arr = ((Object.entries(docs.recommendations.list).filter((part: any) => {
                if (+part[1].doc_id === +cur.doc_id) {
                    return part[1]
                }
            }))).map(i => {
                const d: any = i[1]
                return {
                    num: +d.part_id,
                    doc: +d.doc_id,
                    recommendation: d.part_text,
                    body: +d.body_id,
                    theme: d.part_topics.slice(1, d.part_topics.length - 1).split("^"),
                    followUp: {color: d.part_follow, text: d.part_follow_text},
                    country: d.part_countries.split(","),
                    status: d.part_approved,
                    comment: d.part_comment,
                    link: d.part_links
                }
            })
            setParts(arr)
        }
    }, [cur])

    if (!cur || !parts) return <Loader active={!cur}/>

    return (
        <div>
            <Header/>
            <div className="section_content center">
                <div className="row c12">
                    <div className="wrapper c5">
                        <div id="document-info" className="c5">
                            <h2>
                                {cur.doc_name}
                            </h2>
                            <p>
                                {cur.doc_description}
                            </p>
                            <p>
                                {words ? words.site.aware : 'Be aware, you are looking at truncated version of the document. Please follow the link to see the original publication:'} <a href={cur.doc_link}>{words.site.tooriginal}</a>
                            </p>
                        </div>
                    </div>
                    <div className="text c7">
                        {
                            parts
                                ? parts.map(part => {

                                    return mark && +part.num === +mark
                                        ? <div key={`part${part.num}`}>
                                            <p id={`id${part.num}`} className="paragraph_recommendation marked">
                                                {part.recommendation}
                                            </p>
                                            <div className="recommendation_details">
                                                {
                                                    part.theme.length > 0 ?
                                                        <div className="recommendation_details_themes">
                                                            {
                                                                part.theme.map((theme: any) => {
                                                                    return <div className="theme" key={'topic' + theme}>
                                                                        {docs.topics.list[+theme].topic_name}
                                                                    </div>
                                                                })
                                                            }
                                                        </div> : null
                                                }
                                                <div className="recommendation_details_row">
                                                    <div className="name">
                                                        {capitalize(words.site.body)}:
                                                    </div>
                                                    <div className="value">
                                                        {docs.bodies.list[+part.body].body_name}
                                                        {/*{*/}
                                                        {/*    docs.bodies.list[+part.body].body_description ?*/}
                                                        {/*        <>&nbsp;<sup data-description={docs.bodies.list[+part.body].body_description}*/}
                                                        {/*                     className="mechanism-desctiption">*/}
                                                        {/*            ?*/}
                                                        {/*        </sup></> : null*/}
                                                        {/*}*/}
                                                    </div>
                                                </div>
                                                {
                                                    part.followUp && part.followUp.text
                                                        ? <div className="recommendation_details_row">
                                                            <div className="name">
                                                                {capitalize(words.site.followup)}:
                                                            </div>
                                                            <div className="value">
                                                                {part.followUp.color ? <>{part.followUp.color}<br/></> : ''}
                                                                {part.followUp.text}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        : <p id={`id${part.num}`} key={`part${part.num}`}
                                             className="paragraph_recommendation">
                                            {part.recommendation}
                                        </p>
                                }) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Docs;