import React from "react";
import Helmet from "react-helmet";

//api
import { serverURI } from "../core/config/api.config";
import ApiDocsService from "../core/services/ApiDocsService";

//components
import Footer from "../components/Footer/Footer";
import Loader from "../components/Loader/Loader";

import "../styles.scss";

const Domains = () => {
  const [countries, setCountries] = React.useState<any[]>([]);

  React.useEffect(() => {
    ApiDocsService.getCountries().then((response) => {
      setCountries(response.data);
    });
  }, []);

  // const sorted: any = {};
  // countries.map(item => {
  //     const domain = item.url;
  //     if(!sorted[domain]) {
  //         sorted[domain] = [];
  //     }
  //     sorted[domain].push(item)
  // })

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "0") {
      window.location.href = e.target.value;
    }
  };

  const onClick = (link: string) => {
    window.location.href = link;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        minHeight: "100vh",
      }}
    >
      <Helmet
        title={"UN Human Rights Mechanisms Recommendations"}
        link={[
          { rel: "stylesheet", href: serverURI + "/public/css/work/main.css" },
        ]}
      />
      <Loader active={!countries} />
      <header style={{ background: "#000", padding: "10px 0" }}>
        <div style={{ maxWidth: "1200px", width: "100%", margin: "0 auto" }}>
          <img
            src={"https://api-v3.ccprcentre.org/public/images/logo-CCPR2.png"}
          />
        </div>
      </header>
      <div
        style={{
          minHeight: "400px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div>
          <div
            className={"section_content center"}
            style={{ margin: "30px auto", textAlign: "center" }}
          >
            <h1>UN Human Rights Mechanisms Recommendations</h1>
            {/* <h2 style={{ marginBottom: "10px" }}>
              On civil and political rights
            </h2> */}
            <h3>
              Recommendations adopted by the UN Treaty Bodies and the UN Human
              Rights Council (UPR)
            </h3>
          </div>
          <div
            className="section_results center"
            id={"parts"}
            style={{
              marginTop: "0px",
              paddingTop: "20px",
              textAlign: "center",
              maxWidth: "400px",
            }}
          >
            <div className="container" style={{ borderTop: "1px solid #eee" }}>
              {/*<label className="select" htmlFor="slct">*/}
              {/*    <select id="slct" onChange={onChange}>*/}
              {/*        <option value="0" >Choose a country</option>*/}

              {/*        {*/}
              {/*            countries*/}
              {/*                ? countries.map(item => {*/}
              {/*                    return <option value={`//${item.url}.cobs.7117.by`} key={'link'+item.id}>*/}
              {/*                        {item.country_en}*/}
              {/*                    </option>*/}
              {/*                }) : null*/}
              {/*        }*/}
              {/*    </select>*/}
              {/*    <svg>*/}
              {/*        <use xlinkHref="#select-arrow-down"></use>*/}
              {/*    </svg>*/}
              {/*</label>*/}

              {countries
                ? countries.map((item) => {
                    return (
                      <div
                        key={item.id}
                        onClick={() =>
                          onClick(`http://${item.url}.cobs.ccprcentre.org`)
                        }
                        className={"pointer country-item"}
                      >
                        <div style={{ flex: "0 0 30px", paddingRight: "10px" }}>
                          <img
                            src={"https://api-v3.ccprcentre.org" + item.flag}
                            style={{ maxWidth: "30px" }}
                          />
                        </div>
                        <div>{item.country_en}</div>
                      </div>
                    );
                  })
                : null}
              <svg className="sprites">
                <symbol id="select-arrow-down" viewBox="0 0 10 6">
                  <polyline points="1 1 5 5 9 1"></polyline>
                </symbol>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Domains;
