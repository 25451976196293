import React from "react";
import Helmet from "react-helmet";

//state
import { useRecoilState } from "recoil";
import countryAtom from "../../core/atoms/countryAtom";
import { serverURI } from "../../core/config/api.config";
import wordsAtom from "../../core/atoms/wordsAtom";

//components
import Visualization from "../Visualization/Visualization";
import Menu from "../Menu/Menu";

const HeaderIndex = (props: any) => {
  const { lang } = props;
  const [country] = useRecoilState(countryAtom);
  const [words] = useRecoilState(wordsAtom);

  return (
    <header>
      <Helmet
        script={[
          {
            src:
              serverURI +
              `public/js/work/script-viz.js?q=6rfgwsdfghh78o&v=${lang}`,
            type: "text/javascript",
          },
        ]}
        link={[
          { rel: "stylesheet", href: serverURI + "/public/css/work/main.css" },
        ]}
      />

      <div id="head" className="center">
        <h1>{country.sitename}</h1>

        <h2>
          <span>{country.country}</span>
          {country.flag ? (
            <img className="country-flag" src={serverURI + country.flag} />
          ) : null}
        </h2>

        <Menu />
      </div>

      <Visualization />
    </header>
  );
};

export default HeaderIndex;
