import React from 'react';

//components
import HeaderIndex from "../components/Header/HeaderIndex";
import Documents from "../components/Documents/Documents";

//state
import {useRecoilState} from "recoil";
import langAtom from "../core/atoms/langAtom";

const Main = () => {

    const [lang] = useRecoilState(langAtom);

    React.useEffect(() => {
        if(document.querySelector('#bubbles')) {
            // @ts-ignore
            document.querySelector('#bubbles').remove();
            // @ts-ignore
            document.querySelector('#filter-notes').remove();
        }
    },[lang])

    return (
        <>

            <HeaderIndex lang={lang} />

            <Documents/>

        </>
    )

}

export default Main;