import React from 'react';

//state
import {useRecoilState} from "recoil";
import docsAtom from "../../core/atoms/docsAtom";
import wordsAtom from "../../core/atoms/wordsAtom";

//utils
import capitalize from "../../core/utils/capitalize";

const SingleDoc = (props: any) => {

    const [docs] = useRecoilState<any>(docsAtom);
    const [words] = useRecoilState<any>(wordsAtom);

    const {com} = props;

    let codes: any[] = [];
    if(com.country.length > 0){
        com.country.map((country:any) =>{
            if(docs.countries[+country]){
                codes.push(docs.countries[+country])
            }
        })
    }

    let approved_comment = '';
    let approved_letter = '';
    let approved_style = '';
    if(com.status && +com.status > 0){
        if(+com.status === 1) {
            approved_comment = capitalize(words.site.noted);
            approved_letter = 'N';
            approved_style = 'panel-approved-noted';
        }
        if(+com.status === 2) {
            approved_comment = capitalize(words.site.partly_approved);
            approved_letter = 'PA';
            approved_style = 'panel-approved-partly';
        }
        if(+com.status === 3) {
            approved_comment = capitalize(words.site.approved);
            approved_letter = 'A';
            approved_style = 'panel-approved-approved';
        }
    }

    return (
        <div className="panel">
            <div className="panel-icons-box">
                {
                    codes.length > 0
                        ? <div className="panel-icons-group panel-country"
                             data-name={words.site.countries}
                             data-value={codes.map((code:any) => code.country_name).join(', ')}>
                            {
                                codes.map((code:any) => {
                                    return <div className={`flag flag-${code.country_code}`}
                                                key={'country_'+com.num+code.country_code}></div>
                                })
                            }
                        </div> : null
                }
                {
                    com.status && +com.status > 0
                        ? <div className={`panel-icons-group panel-approved ${approved_style}`}
                               data-name={words.site.status}
                               data-value={approved_comment}>
                            {approved_letter}
                        </div> : null
                }
                {
                    com.followUp.color && com.followUp.color !== '' && com.followUp.text !== ''
                        ? <svg className="panel-icons-group panel-follow-up"
                               data-name={words.site.followup}
                               data-value={com.followUp.text}
                        >
                            <circle cx="20" cy="20" r="20" className={`follow-up fu-red`}></circle>
                            {/*<path*/}
                            {/*    d="M28.3,16l1.7-1.7l-3.7-3.7l-8.8,8.9C16.8,19.2,16,19,15.2,19c-1.4,0-2.7,0.5-3.7,1.5c-2,2-2,5.3,0,7.3	c2,2,5.3,2,7.3,0c1-1,1.5-2.3,1.5-3.7c0-1.1-0.3-2.2-1-3l4.3-4.3l2,2l1.7-1.7l-2-2l1.1-1.1L28.3,16z M17.1,26.1c-1.1,1-2.8,1-3.9,0	c-0.5-0.5-0.8-1.2-0.8-2c0-0.7,0.3-1.4,0.8-2c0.5-0.5,1.2-0.8,2-0.8c0.7,0,1.4,0.3,2,0.8c0.5,0.5,0.8,1.2,0.8,2	C18,24.8,17.7,25.6,17.1,26.1z"></path>*/}
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="-80 -80 650 650" fill={"#fff"}>
                            <polygon points="473.486,182.079 310.615,157.952 235.904,11.23 162.628,158.675 0,184.389 117.584,299.641 91.786,462.257
                                237.732,386.042 384.416,460.829 357.032,298.473 "/>
                            </svg>
                        </svg>
                        : null
                }
            </div>
            <div className="panel-row panel-theme">
                <div className="panel-tag-name">
                    {capitalize(words.site.theme)}:
                </div>
                <div className="panel-tag-value">
                    {
                        com.theme.map((theme:any) => {
                            return <span key={'topic'+theme}>{docs.topics.list[+theme].topic_name}</span>
                        })
                    }
                </div>
            </div>
            {
                com.body ?
                    <div className="panel-row panel-theme">
                        <div className="panel-tag-name">
                            {capitalize(words.site.body)}:
                        </div>
                        <div className="panel-tag-value">
                            {docs.bodies.list[+com.body].body_name}
                            {
                                docs.bodies.list[+com.body].body_description ?
                                <>&nbsp;<sup data-description={docs.bodies.list[+com.body].body_description}
                                     className="mechanism-desctiption">
                                    ?
                                </sup></> : null
                            }
                        </div>
                    </div> : null
            }
            <div className="panel-row panel-document">
                <div className="panel-tag-name">{capitalize(words.site.document)}:</div>
                <div className="panel-tag-value">
                    {
                        docs.docs.list[+com.doc].doc_name
                    }
                </div>
            </div>
            <div className="panel-row panel-text">
                <div className="panel-tag-name">{words.site.article}:</div>
                <div className="panel-tag-value">
                    {
                        com.recommendation
                    }<br/>
                    <a href={`/doc/${docs.docs.list[+com.doc].doc_alias}?mark=${com.num}#${com.num}`}
                       target="_blank">
                        {words.site.more}
                        <svg>
                            <path d="M12,12H2V2h3V0H0v14h14V9h-2V12z"></path>
                            <path d="M7,0v2h3.6L5.3,7.3l1.4,1.4L12,3.4V7h2V0H7z"></path>
                        </svg>
                    </a>
                </div>
            </div>
            {
                com.link && com.link !== '[]'
                    ? <div className="panel-row panel-link">
                        <div className="panel-tag-name">
                            {capitalize(words.site.links)}:
                        </div>
                        <div className="panel-tag-value">
                            {
                                JSON.parse(com.link).map((link:any, i:number) => {
                                    return <span key={i+'link'+com.num}><a href={link.link}>
                                        {link.text}
                                    </a></span>
                                })
                            }
                        </div>
                    </div> : null
            }
            {
                com.comment
                    ? <div className="panel-row panel-comment collapsed">
                        <div className="panel-tag-name">
                            {words.site.comment}
                        </div>
                        <div className="panel-tag-value" style={{height: "0px"}}>
                            {com.comment}
                        </div>
                    </div> : null
            }
        </div>
    )

}

export default SingleDoc;