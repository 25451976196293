import React from 'react';
import Helmet from "react-helmet";

//state
import {useRecoilState} from "recoil";
import countryAtom from "../../core/atoms/countryAtom";
import {serverURI} from "../../core/config/api.config";

//components
import Menu from "../Menu/Menu";

const Header = () => {

    const [country] = useRecoilState(countryAtom);

    return (
        <header>
            <Helmet
                script={[]}
                link={[
                    {"rel": "stylesheet", "href": serverURI + 'public/css/work/info.css'},
                ]}
            />

            <div id="head" className="center">
                <h1>{country.sitename}</h1>

                <h2>
                    <span>{country.country}</span>
                    {
                        country.flag
                        ? <img className="country-flag" src={serverURI + country.flag}/> : null
                    }

                </h2>

                <Menu />
            </div>

        </header>
    )

}

export default Header;