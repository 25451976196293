import React from 'react';

//state
import {useRecoilState} from "recoil";
import {serverURI} from "../../core/config/api.config";
import wordsAtom from "../../core/atoms/wordsAtom";
import showResetAtom from "../../core/atoms/showResetArom";

const Visualization = () => {

    const [words] = useRecoilState(wordsAtom);
    const [showReset, setShowReset] = useRecoilState(showResetAtom);

    return (
        <div id="viz" className="center flexbox">

            <div id="viz-details">

                <p id="vd-intro">
                    {
                        words && words.site
                        ? words.site.fullbase
                            : 'Full recommendations database for Vietnam contains:'
                    }
                </p>

                <div id="vd-counters">

                    <div className="vd-counter" id="vd-recommendation">
                        <img className="vd-icon" src={`${serverURI}public/svg/icon-recommendations.svg`} />
                        <div className="wrapper">
                            <div className="vd-number">0</div>
                            <div className="vd-name">recommendations</div>
                        </div>
                    </div>

                    <div className="vd-counter" id="vd-body">
                        <img className="vd-icon" src={`${serverURI}public/svg/icon-bodies.svg`} />
                        <div className="wrapper">
                            <div className="vd-number">0</div>
                            <div className="vd-name">UN Human Rights Mechanisms</div>
                        </div>
                    </div>

                    <div className="vd-counter" id="vd-theme">
                        <img className="vd-icon" src={`${serverURI}public/svg/icon-themes.svg`} />
                        <div className="wrapper">
                            <div className="vd-number">0</div>
                            <div className="vd-name">themes</div>
                        </div>
                    </div>

                </div>


                <p id="vd-instructions">You can find every recommendation as a bubble at the scheme to the right. Hover
                    bubbles to see details, click at the bubble to go to the entire document.</p>
                <p id="vd-colors">Colors correspond to mechanisms <sup>?</sup></p>

            </div>

            <div id="svg-wrapper">
                <svg></svg>
            </div>

            <ul id="viz-toggle">
                <li id="vt-recommendation">{words && words.site ? words.site.bymechanisms : 'By mechanisms'}</li>
                <li id="vt-theme">{words && words.site ? words.site.bythemes : 'By Themes'}</li>
            </ul>
            <div id="viz-reset-filters" className={showReset ? '' : 'hidden'} onClick={() => setShowReset(false)}>
                {words.site && words.site.reset ? words.site.reset : ''}
            </div>

        </div>
    )

}

export default Visualization;