import React from "react";
import { Route, Routes } from "react-router-dom";

import ApiDocsService from "../core/services/ApiDocsService";
import CountryInterface from "../core/models/CountryInterface";

//state
import { useRecoilState } from "recoil";
import domainAtom from "../core/atoms/domainAtom";
import langAtom from "../core/atoms/langAtom";
import docsAtom from "../core/atoms/docsAtom";
import countryAtom from "../core/atoms/countryAtom";
import wordsAtom from "../core/atoms/wordsAtom";

//components
import Main from "../pages/Main";
import Meta from "./Meta/Meta";
import Footer from "./Footer/Footer";
import Lang from "./Lang/Lang";
import Docs from "../pages/Docs";
import Pages from "../pages/Pages";
import Domains from "../pages/Domains";
import Loader from "./Loader/Loader";

/**
 * 
 * window.location.host =>    cobs.dev.ccprcentre.org

 */

const App = () => {
  const host = window.location.host;
  
  //const host = "http://vietnam.cobs.7117.by";
  const subdomain = host
    .replace("http://", "")
    .replace("https://", "")
    .split(".");

  const [domain, setDomain] = useRecoilState(domainAtom);

  const [langList, setLangList] = React.useState<string[]>([]);

  const [lang, setLang] = useRecoilState<string>(langAtom);
  const [docs, setDocs] = useRecoilState(docsAtom);
  const [country, setCountry] = useRecoilState(countryAtom);
  const [words, setWords] = useRecoilState(wordsAtom);

  React.useEffect(() => {
    if (subdomain[0] !== "cobs") {
      setDomain(subdomain[0]);
      init(true);
    }
  }, []);

  React.useEffect(() => {
    if (subdomain[0] !== "cobs") {
      if (typeof lang !== "undefined") {
        localStorage.setItem("lang", String(lang));
      }
      getData();
      init();
      getWords();
    }
  }, [lang, domain]);

  const init = (firstInit = false) => {
    ApiDocsService.init(`?domain=${subdomain[0]}`).then((response) => {
      let selected;
      if (response.data.length > 1) {
        selected = response.data.find((item: CountryInterface) => {
          if (lang) return item.lang === lang;
          return +item.def === 1;
        });
      } else {
        selected = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
      }
      setCountry(selected);
      if (!lang) {
        setLang(selected.lang);
      }

      if (firstInit) {
        setLangList(
          response.data.map((item: CountryInterface) => {
            return item.lang;
          })
        );
        setLang(selected.lang);
      }
    });
  };

  const getData = () => {
    ApiDocsService.getAll(`?domain=${subdomain[0]}&lang=${lang}`).then(
      (data) => {
        setDocs(data.data);
      }
    );
  };

  const getWords = () => {
    if (lang) {
      ApiDocsService.getLang(lang).then((data) => {
        setWords(data.data);
      });
    }
  };

  if (subdomain[0] === "cobs") {
    return <Domains />;
  } else {
    if (!domain || domain === "") {
      return <Loader active={true} />;
    }

    return (
      <>
        <Meta />

        <Lang langList={langList} default={lang} />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/doc/:url" element={<Docs />} />
          <Route path="/*" element={<Pages />} />
        </Routes>

        <Footer />
      </>
    );
  }
};

export default App;
