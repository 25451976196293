import React from 'react';

//state
import {serverURI} from "../../core/config/api.config";
import {useRecoilState} from "recoil";
import domainAtom from "../../core/atoms/domainAtom";

//components
import Menu from "../Menu/Menu";

const Footer = () => {

    const [domain] = useRecoilState(domainAtom);

    return (
        <footer>

            <div className="wrapper">

                {
                    domain && domain !== '' ? <Menu /> : null
                }

                <div id="credits" className="flexbox">
                    <div id="producers">
                        <div id="logos" className="d-flex">
                            <a href="http://ccprcentre.org/" target="_blank">
                                <img src={`${serverURI}public/images/logo-CCPR.png`}/>
                            </a>
                            <a href="https://www.loro.ch/" target="_blank">
                                <img src={`${serverURI}public/svg/logo_LoRo.svg`}/>
                            </a>
                            <a href="https://www.geneve.ch/fr" target="_blank">
                                <img src={`${serverURI}public/images/les-soutien.jpg`}/>
                            </a>

                        </div>
                        <div id="copy">
                            <p>© 2022. Centre for Civil and Political Rights</p>
                        </div>
                    </div>
                    <div id="workgroup">
                        <p>Product management: <a href="https://www.linkedin.com/in/yuliya-khlashchankova-019a23157"
                                                  title="Product manager" target="_blank">Yullya Khlashchankova</a></p>
                        <p>Design: <a href="http://czrt.by" title="Infographic and Data Visualization" target="_blank">Ihar
                            Yanouski</a></p>
                        <p>Development: <a href="https://www.linkedin.com/in/vladimir-litovchenko-a5853916a/" title="Arktur Solutions Web Development"
                                           target="_blank">Arktur.Solutions</a></p>
                    </div>
                </div>

            </div>

        </footer>
    )

}

export default Footer;