import React from 'react';
import {useLocation} from "react-router-dom";
import Helmet from "react-helmet";

//state
import {useRecoilState} from "recoil";
import ApiDocsService from "../../core/services/ApiDocsService";
import domainAtom from "../../core/atoms/domainAtom";
import langAtom from "../../core/atoms/langAtom";

const Meta = (props: any) => {

    const {content = false} = props;

    const { pathname } = useLocation();
    const [domain] = useRecoilState(domainAtom);
    const [lang] = useRecoilState<string>(langAtom);

    const [meta, setMeta] = React.useState({
        title: 'CCPR Centre',
        description: 'This is another page',
        node_content: ''
    })

    React.useEffect(() => {
        getMeta()
    },[])

    React.useEffect(() => {
        getMeta()
    },[pathname])

    const getMeta = () => {
        const url = pathname.slice(1);
        const uri = url.length === 0 ? 'index' : url;

        ApiDocsService.getMeta(`?domain=${domain}&uri=${uri}${lang ? `&lang=${lang}` : ''}`)
            .then((response:any) => {
                setMeta({
                    title: response.data.node_title,
                    description: response.data.node_description,
                    node_content: response.data.node_content
                })
            })
    }

    function createMarkup() {
        return {__html: meta.node_content};
    }

    if(content) {
        return <div dangerouslySetInnerHTML={createMarkup()}/>
    }

    return (
        <Helmet htmlAttributes={{"lang": "en"}} // amp takes no value
                title={meta.title}
                meta={[
                    {"name": "description", "content": meta.description}
                ]}
        />
    )

}

export default Meta;