import React from "react";
import { Link, useLocation } from "react-router-dom";

import ApiDocsService from "../../core/services/ApiDocsService";

//state
import { useRecoilState } from "recoil";
import domainAtom from "../../core/atoms/domainAtom";
import langAtom from "../../core/atoms/langAtom";

const Menu = () => {
  const [menu, setMenu] = React.useState([]);
  const { pathname } = useLocation();

  const [domain] = useRecoilState(domainAtom);
  const [lang] = useRecoilState(langAtom);

  React.useEffect(() => {
    ApiDocsService.getMenu(`?domain=${domain}&lang=${lang}`).then(
      (response) => {
        const result = Array.isArray(response.data) ? response.data : [];
        if (result.length === 0) {
          throw Error("No menu items !!!!");
        }
        setMenu(result);
      }
    );
  }, [lang]);

  return (
    <nav>
      {menu && menu.length > 0 ? (
        <ul className={"main-navigation"}>
          {menu.map((item: any) => {
            const url =
              "/" + (item.node_alias !== "index" ? item.node_alias : "");
            return (
              <li key={`menu_item_${item.menus_link_id}`}>
                <Link
                  to={item.node_alias !== "index" ? url : "/"}
                  className={url === pathname ? "active" : ""}
                >
                  {item.menus_link_name}
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </nav>
  );
};

export default Menu;
