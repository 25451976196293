import {atom} from "recoil";

const countryAtom = atom({
    key: 'country',
    default: {
        id: 0,
        country: 'Loading...',
        description: 'Loading...',
        sitename: 'Loading...',
        flag: ''
    }
});

export default countryAtom;