import React, {useRef} from 'react';
import Pagination from "react-js-pagination";

//state
import {useRecoilState} from "recoil";
import wordsAtom from "../../core/atoms/wordsAtom";
import docsAtom from "../../core/atoms/docsAtom";
import showResetAtom from "../../core/atoms/showResetArom";

//components
import SingleDoc from "./SingleDoc";

//utils
import paginate from "../../core/utils/paginate";

const Documents = () => {

    const limit = 20;

    const tRef = useRef();

    React.useEffect(() => {
        if(document.querySelector('#viz')) {
            document.querySelector('#viz').addEventListener('click', () => {
                if (tRef && tRef.current && tRef.current.value) {
                    if(!init && tRef.current.value !== '0') setInit(true)
                    setTopic(+tRef.current.value)
                }
            })
            return () => {
                document.querySelector('#viz').removeEventListener('click', () => {
                    if (tRef && tRef.current && tRef.current.value) {
                        if(!init && tRef.current.value !== '0') setInit(true)
                        setTopic(+tRef.current.value)
                    }
                })
            }
        }
    },[])
    const [words] = useRecoilState<any>(wordsAtom);
    const [docs] = useRecoilState<any>(docsAtom);

    const [init, setInit] = React.useState(false);
    const [showReset, setShowReset] = useRecoilState(showResetAtom);

    const [coms, setComs] = React.useState<any>([])
    const [page, setPage] = React.useState<number>(1)

    const [body, setBody] = React.useState(0);
    const [topic, setTopic] = React.useState(0);

    React.useEffect(() => {
        setShowReset(+topic > 0 || +body > 0)
    },[topic, body])

    React.useEffect(() => {
        if(docs.recommendations && docs.recommendations.list) {
            setPage(1);
            setComs(getComs)
        }
    },[docs, body, topic])

    const getComs = () => {
        let list = Object.entries(docs.recommendations.list).map((rd:any) => {
            const d = rd[1];
            return {
                num: +d.part_id,
                doc: +d.doc_id,
                recommendation: d.part_text,
                body: +d.body_id,
                theme: d.part_topics.slice(1, d.part_topics.length-1).split("^"),
                followUp: { color: d.part_follow, text: d.part_follow_text },
                country: d.part_countries.split(","),
                status: d.part_approved,
                comment: d.part_comment,
                link: d.part_links
            }
        });
        if(body){
            list = list.filter(item => {
                return item.body === body
            })
        }
        if(topic){
            list = list.filter(item => {
                return item.theme.includes(String(topic))
            })
        }
        return list;
    }

    const bodyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if(!init) setInit(true)
        setBody(+e.target.value)
    }
    const topicChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if(!init) setInit(true)
        setTopic(+e.target.value)
    }

    const changePage = (page:number) => {
        setPage(page);
        // @ts-ignore
        document.getElementById("filters").scrollIntoView({
            behavior: 'smooth'
        });
    }

    if(typeof words.site === 'undefined') {
        return <div>Loading...</div>
    }

    const interActions = () => {
        if(window.$setListInteractions && coms.length > 0) setTimeout(window.$setListInteractions, 100)
    }

    const sortByTopicName = (jsonArray: any) => {

        jsonArray.sort((a: any, b: any) => {
          const topicNameA = a[1].topic_name.toLowerCase();
          const topicNameB = b[1].topic_name.toLowerCase();
          if (topicNameA < topicNameB) {
            return -1;
          }
          if (topicNameA > topicNameB) {
            return 1;
          }
          return 0;
        });
    
        return jsonArray;
      };

    interActions();

    // @ts-ignore
    return (
        <React.StrictMode>
            <div id="filters">

                <div className="wrapper flexbox center">

                    <div id={'filter-notes'}>
                        <p className="note">
                            {words.site.starttext}
                        </p>
                        <div id="reset-filters" className={showReset ? '' : 'hidden'} onClick={() => setShowReset(false)}>
                            {words.site.reset}
                        </div>
                    </div>

                    <div id="filter-boxes">

                        <div className="filter-box" id="fbody">
                            <h4>
                                {words.site.mechanisms}
                            </h4>
                            <select className="form-control" id="body_id"
                                    onChange={bodyChange}
                                    name={'body'}>
                                <option value="0">
                                    {words.site.allmechamisms}
                                </option>
                                {
                                    docs && docs.bodies && docs.bodies.list ?
                                        Object.entries(docs.bodies.list).map((item:any) => {
                                            const doc = item[1];
                                            return <option value={doc.body_id} key={'body'+doc.body_id}>
                                                {doc.body_name}
                                            </option>
                                        }) : null
                                }
                            </select>
                        </div>

                        <div className="filter-box" id="ftheme">
                            <h4>
                                {words.site.themes}
                            </h4>
                            <select className="form-control" id="topic_id" ref={tRef}
                                    onChange={topicChange}
                                    name={'topic'}>
                                <option value="0">
                                    {words.site.allthemes}
                                </option>
                                {
                                    docs && docs.topics && docs.topics.list ?
                                        sortByTopicName(Object.entries(docs.topics.list)).map((item:any) => {
                                            const topic = item[1];
                                            return <option value={topic.topic_id} key={'topic'+topic.topic_id}>
                                                {topic.topic_name}
                                            </option>
                                        }) : null
                                }
                            </select>
                        </div>

                    </div>

                </div>

            </div>


            <div className="section_results center">
                <div className="container" id="parts">
                    {
                        !init ?
                            <div id="intro-message">
                                {words.site.select}
                            </div> :
                                coms.length > 0
                                    ? paginate(coms.map((item:any) => {
                                        return <SingleDoc com={item} key={'part_'+item.num}/>
                                    }),limit,page) : null
                    }
                    {
                        coms.length > limit && init
                        ?<Pagination
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={coms.length}
                                pageRangeDisplayed={5}
                                onChange={changePage}
                                innerClass="pagination"
                                itemClass=""
                                activeClass="active"
                                linkClass=""
                            />: null
                    }
                </div>
                <div id="paginator"></div>
            </div>

            <div id="pop-up" className="hidden">
                <div className="cover">
                    <div className="wrapper"></div>
                    <div id="pu-window" style={{display: "block", left: "388.5px", top: "299.5px"}}>
                        <header>
                            <h2>Colors legend</h2>
                            <svg className="pu-close" width="17" height="17">
                                <line x1="1" x2="15" y1="1" y2="15"></line>
                                <line x1="15" x2="1" y1="1" y2="15"></line>
                            </svg>
                        </header>
                        <div className="pu-content">
                        </div>
                    </div>
                </div>
            </div>
        </React.StrictMode>
    )

}

export default Documents;