import axios, { AxiosResponse } from "axios";
import { serverURI } from "../config/api.config";

export const ApiDocsService = {
  init(params: string) {
    return axios
      .get(serverURI + `init` + params)
      .then((response: AxiosResponse) => {
        return response;
      });
  },

  getAll(params: string) {
    return axios
      .get(serverURI + `docs/all` + params)
      .then((response: AxiosResponse) => {
        return response;
      });
  },

  getMenu(params: string) {
    return axios
      .get(serverURI + `menu` + params)
      .then((response: AxiosResponse) => {
        return response;
      });
  },

  getMeta(params: string) {
    return axios
      .get(serverURI + `meta` + params)
      .then((response: AxiosResponse) => {
        return response;
      });
  },

  getLang(lang: string) {
    return axios
      .get(serverURI + `lang/` + lang + "?domain=vietnam")
      .then((response: AxiosResponse) => {
        return response;
      });
  },

  getCountries() {
    return axios
      .get(serverURI + `countries`)
      .then((response: AxiosResponse) => {
        return response;
      });
  },
};

export default ApiDocsService;
