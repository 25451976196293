import React from 'react';

//components
import Header from "../components/Header/Header";
import Meta from "../components/Meta/Meta";

const Pages = () => {

    return (
        <div className={'section_content center'}>
            <Header />
            <Meta content={true}/>
        </div>
    )

}

export default Pages;